import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class DataTable extends Component {
  static propTypes = {
    rows: PropTypes.array.isRequired,
    head: PropTypes.array,
    columns: PropTypes.number,
    className: PropTypes.string,
    renderRow: PropTypes.func.isRequired,
    renderHead: PropTypes.func,
    renderPagination: PropTypes.func,
    fieldsOrder: PropTypes.array,
    courtIds: PropTypes.array,
    filters: PropTypes.object,
    handleFilterChange: PropTypes.func,
  }

  static defaultProps = {
    columns: 0,
    renderHead: () => {},
    renderPagination: () => {},
    filters: {},
    handleFilterChange: () => {},
  }

  render() {
    const {
      rows,
      head,
      columns,
      className,
      renderRow,
      renderHead,
      renderPagination,
      fieldsOrder,
      courtIds,
      filters,
      handleFilterChange,
    } = this.props

    const theadMarkup = renderHead(head, {
      fieldsOrder,
      filters,
      handleFilterChange,
    })

    const filteredRows = rows.filter((row) => {
      return Object.keys(filters).every((key) => {
        let filterValues = filters[key] || []

        if (filterValues.includes('Blank')) {
          filterValues = [...filterValues, null, 'null', '']
        }

        if (filterValues.length === 0) return true

        return filterValues.includes(String(row[key]))
      })
    })

    const tbodyMarkup = filteredRows.map((row, index) =>
      renderRow(row, { index, fieldsOrder, courtIds }),
    )

    return (
      <div className="data-table-wrapper">
        <table className={className}>
          <thead>{theadMarkup}</thead>
          <tbody>{tbodyMarkup}</tbody>
        </table>
        {renderPagination && renderPagination()} {}
      </div>
    )
  }
}
