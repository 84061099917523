import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { keys } from 'lodash/fp'
import classNames from 'classnames'
import Button from '../engine-ui/Button'
import DataTable from '../engine-ui/DataTable'
import Tooltip from '../engine-ui/Tooltip'

import { t, mapFieldToOptionKeys } from './helpers'

export default class PaymentsDataTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    onEdit: PropTypes.func.isRequired,
    onClone: PropTypes.func.isRequired,
    onSplit: PropTypes.func,
    onCancel: PropTypes.func,
    onMarkPaid: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    fieldsOrder: PropTypes.array,
    translations: PropTypes.object,
  }

  state = {
    filters: {},
    showFilterDropdown: {},
  }

  filterableColumns = [
    'parent_reference',
    'amount',
    'direction',
    'payment_date',
    'category',
    'payment_type',
    'party',
    'court_instance',
    'payment_purpose',
    'updated_at',
    'update_agent',
  ]

  handleFilterChange = (field, selectedValues) => {
    this.setState((prevState) => {
      const newFilters = { ...prevState.filters, [field]: selectedValues }
      return { filters: newFilters }
    })
  }

  handleClickOutside = (event) => {
    if (!this.filterContainerRef) return

    const { showFilterDropdown } = this.state
    const openFilterField = Object.keys(showFilterDropdown).find(
      (key) => showFilterDropdown[key],
    )

    if (openFilterField) {
      const filterElement = this.filterContainerRef[openFilterField]
      if (filterElement && !filterElement.contains(event.target)) {
        this.closeFilterDropdown(openFilterField)
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  toggleFilterDropdown = (field) => {
    this.setState((prevState) => {
      const newShowFilterDropdown = { ...prevState.showFilterDropdown }
      Object.keys(newShowFilterDropdown).forEach((key) => {
        if (key !== field) {
          newShowFilterDropdown[key] = false
        }
      })
      newShowFilterDropdown[field] = !newShowFilterDropdown[field]
      return { showFilterDropdown: newShowFilterDropdown }
    })
  }

  getUniqueValuesForField = (field) => {
    const { data, translations } = this.props

    const uniqueValues = new Set()
    let hasNullOrEmpty = false

    data.forEach((row) => {
      let value = row[field]
      if (value === null || value === '' || value?.trim?.() === '') {
        hasNullOrEmpty = true
      } else {
        uniqueValues.add(
          typeof value === 'number'
            ? value.toString()
            : translations[value] || value,
        )
      }
    })

    let sortedValues = [...uniqueValues].sort()
    if (hasNullOrEmpty) {
      sortedValues.unshift('Blank')
    }

    return sortedValues
  }

  handleCheckboxChange = (field, event, optionValue) => {
    this.setState((prevState) => {
      let selectedValues = prevState.filters[field] || []

      if (event.target.checked) {
        selectedValues.push(optionValue)
      } else {
        selectedValues = selectedValues.filter((val) => val !== optionValue)
      }

      return {
        filters: {
          ...prevState.filters,
          [field]: selectedValues,
        },
      }
    })
  }

  applyFilters = (rowData) => {
    const { filters } = this.state

    return Object.entries(filters).every(([field, selectedValues]) => {
      if (!selectedValues.length) return true

      const cellValue = rowData[field]
      const isBlank =
        cellValue === null ||
        cellValue === '' ||
        (typeof cellValue === 'string' && cellValue.trim() === '')

      if (selectedValues.includes('Blank')) {
        if (isBlank) return true
        return selectedValues.some(
          (val) => val !== 'Blank' && val === cellValue?.toString(),
        )
      }

      return selectedValues.includes(cellValue?.toString())
    })
  }

  handleSelectAll = (field) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: this.getUniqueValuesForField(field),
      },
    })
  }

  handleClearSelection = (field) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: [],
      },
    })
  }

  closeFilterDropdown = (field) => {
    this.setState({
      showFilterDropdown: {
        ...this.state.showFilterDropdown,
        [field]: false,
      },
    })
  }

  renderHead = (head, { fieldsOrder }) => {
    const { translations } = this.props
    const { filters, showFilterDropdown } = this.state

    return (
      <tr className="tab-table-row">
        <td>#</td>
        {fieldsOrder.map((field, index) => {
          if (!this.filterableColumns.includes(field)) {
            return (
              <td key={index} className="text-center">
                {translations[field]}
              </td>
            )
          }

          const selectedValues = filters[field] || []
          const selectedCount = selectedValues.length

          return (
            <td key={index} className="text-center">
              <div className="d-flex align-items-center justify-content-center">
                <span>{translations[field]}</span>
                <Tooltip text={`Filter ${translations[field]}`} placement="top">
                  <button
                    className={classNames(
                      'btn py-0 px-1 payments-table__action-icon',
                      { 'btn-filter': selectedCount > 0 },
                    )}
                    onClick={() => this.toggleFilterDropdown(field)}
                  >
                    <i
                      className={classNames('fas fa-filter', {
                        'fa-check': selectedCount > 0,
                      })}
                    />
                    {selectedCount > 0 && (
                      <span className="badge bg-info-number ms-1">
                        {selectedCount}
                      </span>
                    )}
                  </button>
                </Tooltip>
              </div>

              {showFilterDropdown[field] && (
                <div
                  className="filter-dropdown"
                  ref={(el) =>
                    (this.filterContainerRef = {
                      ...this.filterContainerRef,
                      [field]: el,
                    })
                  }
                >
                  <div style={{ marginBottom: '10px' }}>
                    <span onClick={() => this.handleSelectAll(field)}>
                      Select All
                    </span>
                    <span onClick={() => this.handleClearSelection(field)}>
                      Clear
                    </span>
                    <span
                      style={{ marginLeft: '40px' }}
                      onClick={() => this.closeFilterDropdown(field)}
                    >
                      X
                    </span>
                  </div>
                  <div className="filter-options">
                    {this.getUniqueValuesForField(field).map(
                      (optionValue, idx) => {
                        const translatedOption = mapFieldToOptionKeys[field]
                          ? t(optionValue)(
                              translations[mapFieldToOptionKeys[field]],
                            )
                          : optionValue
                        return (
                          <div key={optionValue} className="checkbox-option">
                            <label>
                              <input
                                type="checkbox"
                                value={optionValue}
                                checked={selectedValues.includes(optionValue)}
                                onChange={(e) =>
                                  this.handleCheckboxChange(
                                    field,
                                    e,
                                    optionValue,
                                  )
                                }
                                style={{ marginRight: '0.5rem' }}
                              />
                              {translatedOption}
                            </label>
                            {idx <
                              this.getUniqueValuesForField(field).length -
                                1 && <hr className="dropdown-separator" />}
                          </div>
                        )
                      },
                    )}
                  </div>
                </div>
              )}
            </td>
          )
        })}
        <td className="text-end pe-2">Aktionen</td>
      </tr>
    )
  }

  renderRow = (rowData, { index: rowIndex, fieldsOrder }) => {
    const {
      onEdit,
      onClone,
      onCancel,
      onMarkPaid,
      onSplit,
      onDelete,
      onArchive,
      translations,
    } = this.props
    return (
      <tr key={rowIndex} className={`tab-table-row ${rowData.table_tr_class}`}>
        <td>{rowIndex + 1}</td>
        {fieldsOrder.map((field, cellIndex) => {
          const value = mapFieldToOptionKeys[field]
            ? t(rowData[field])(translations[mapFieldToOptionKeys[field]])
            : rowData[field]

          const innerCellClassName = classNames({
            'text-end': field === 'amount',
            'text-center': field !== 'amount',
            'question_display-checkbox': field === 'on_hold',
            'question_display-checkbox-true': field === 'on_hold' && value,
          })

          const tooltip = (value || '').toString().length > 20 ? value : ''
          let formattedValue =
            typeof value === 'string'
              ? value.length > 20
                ? `${value.substring(0, 20)}...`
                : value
              : value

          const isDirectionGrey = rowData.table_tr_class.includes('grey')
          if (formattedValue === 'eingehend') {
            formattedValue = (
              <Tooltip text="eingehend" placement="top">
                <i
                  style={{ margin: 'auto' }}
                  className={`fas fa-sign-in-alt ${
                    isDirectionGrey ? '' : 'text-success'
                  }`}
                />
              </Tooltip>
            )
          } else if (formattedValue === 'ausgehend') {
            formattedValue = (
              <Tooltip text="ausgehend" placement="top">
                <i
                  style={{ margin: 'auto' }}
                  className={`fas fa-sign-out-alt fa-rotate-180 ${
                    isDirectionGrey ? '' : 'text-danger'
                  }`}
                />
              </Tooltip>
            )
          }
          switch (formattedValue) {
            case 'Conny an Kanzlei':
              formattedValue = 'C -> K'
              break
            case 'Kanzlei an Conny':
              formattedValue = 'K -> C'
              break
            case 'Sàrl an Kanzlei':
              formattedValue = 'S -> K'
              break
            case 'Kanzlei an Sàrl':
              formattedValue = 'K -> S'
              break
          }

          return (
            <td
              key={cellIndex}
              title={tooltip}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
            >
              <div className={innerCellClassName}>{formattedValue}</div>
            </td>
          )
        })}
        <td className="ps-0 payments-table__cell text-end pe-2">
          {rowData.invoice_or_verdict ? (
            <Tooltip text="Als bezahlt eintragen" placement="top">
              <Button
                value={rowData.id}
                onClick={onMarkPaid}
                className="btn py-0 px-1"
              >
                <i className="fas fa-euro payments-table__action-icon" />
              </Button>
            </Tooltip>
          ) : null}
          {rowData.invoice_or_verdict ? (
            <Tooltip text="Stornieren" placement="top">
              <Button
                value={rowData.id}
                onClick={onCancel}
                className="btn py-0 px-1"
              >
                <i className="fas fa-ban payments-table__action-icon" />
              </Button>
            </Tooltip>
          ) : null}
          {rowData.incoming && rowData.payment && !rowData.child ? (
            <Tooltip text="Aufteilen" placement="top">
              <Button
                value={rowData.id}
                onClick={onSplit}
                className="btn py-0 px-1"
              >
                <i className="fas fa-plus payments-table__action-icon" />
              </Button>
            </Tooltip>
          ) : null}
          <Tooltip text="Kopieren" placement="top">
            <Button
              value={rowData.id}
              onClick={onClone}
              className="btn py-0 px-1"
            >
              <i className="fas fa-clone payments-table__action-icon" />
            </Button>
          </Tooltip>

          <Tooltip text="Editieren" placement="top">
            <Button
              value={rowData.id}
              onClick={onEdit}
              className="btn py-0 px-1"
            >
              <i className="fas fa-edit payments-table__action-icon" />
            </Button>
          </Tooltip>
          <Tooltip text="Löschen" placement="top">
            <Button value={rowData.id} onClick={onDelete} className="btn p-0">
              <i className="fas fa-trash-alt payments-table__action-icon" />
            </Button>
          </Tooltip>
        </td>
      </tr>
    )
  }

  render() {
    const { data, fieldsOrder } = this.props
    const { filters } = this.state

    return (
      <DataTable
        rows={data.filter(this.applyFilters)}
        head={keys(data)}
        renderRow={this.renderRow}
        className="table table-striped table-dark-engine-funnel"
        renderHead={this.renderHead}
        fieldsOrder={fieldsOrder}
        filters={filters}
        handleFilterChange={this.handleFilterChange}
      />
    )
  }
}
