import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 2.5rem 2.5rem 2.5rem;
  grid-gap: 0.5rem 1rem;
  padding-top: 3rem;
`

export const Label = styled.label`
  grid-column: 1 / 2;
  color: white;
  font-size: 0.9rem;
`

export const Input = styled.input`
  grid-column: 2 / 4;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
`

export const ErrorMessage = styled.p`
  grid-column: 1 / 4;
  font-size: 0.8rem;
  font-weight: 600;
  color: #e82201;
`

export const Button = styled.button`
  grid-column: 3 / 4;
  justify-self: end;
  background-color: #2ecc71;
  color: #f7f7f9;
  border-radius: 20px;
  border: 1px solid #2ecc71;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.375rem 1rem;
  width: 80px;

  &:hover:not(:disabled) {
    filter: brightness(80%);
  }

  &:active:not(:disabled) {
    filter: brightness(60%);
  }

  &:disabled {
    opacity: 0.65;
  }
`
