import React, { useState, useContext } from 'react'
import {
  Container,
  Input,
  Button,
  ErrorMessage,
  Label,
} from './styles/documentSplit'
import Context from '../../context/Context'
import useCallbackStatus from '../../hooks/useCallbackStatus'
import client from '../../../../../../client'

export default function DocumentSplit({ maxPages }) {
  const [pageRange, setPageRange] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const { documentId, ziebToken } = useContext(Context)
  const { run: runSplitDocument, isPending } = useCallbackStatus()

  const validatePageRange = (input, maxPages) => {
    const sanitizedInput = input.replace(/\s+/g, '')
    const regex = /^(\d+(-\d+)?)(,\d+(-\d+)?)*$/

    if (!regex.test(sanitizedInput)) {
      return 'Ungültiges Format. Verwenden Sie eine druckerähnliche Eingabe (z.B. 1-4,5,6).'
    }

    const ranges = sanitizedInput
      .split(',')
      .map((part) => part.split('-').map(Number))

    for (let range of ranges) {
      const [start, end] = range

      if (maxPages > 0) {
        if (range.length === 1) {
          if (start < 1 || start > maxPages) {
            return `Seitenzahl außerhalb des Bereichs (1-${maxPages}).`
          }
        } else if (range.length === 2) {
          if (start < 1 || end > maxPages) {
            return `Der Bereich ${start}-${end} liegt außerhalb des zulässigen Seitenbereichs (1-${maxPages}).`
          }
        }
      }

      if (range.length === 2 && start >= end) {
        return `Ungültiger Bereich: ${start}-${end}. Die linke Zahl muss kleiner sein als die rechte.`
      }
    }

    return ''
  }

  const handleSubmit = () => {
    setError('')
    setSuccess('')

    const validationError = validatePageRange(pageRange, maxPages)
    if (validationError) {
      setError(validationError)
      return
    }

    const payload = { pages: pageRange }
    const headers = { token: ziebToken }

    runSplitDocument(
      client.post(`/api/zieb/documents/${documentId}/split`, payload, headers),
    )
      .then(({ status, result }) => {
        if (result.status === 'ok') {
          setSuccess('Dokument erfolgreich geteilt!')

          const { edit_url, document_name, document_id } = result.document

          V2.trigger('document.show', {
            url: edit_url,
            filename: document_name,
            documentId: document_id,
          })
        } else {
          setError('Das Dokument konnte nicht geteilt werden.')
        }
      })
      .catch(() => {
        setError('Beim Splitten ist ein Fehler aufgetreten.')
      })
  }

  return (
    <Container>
      <Label>Seiten aufteilen:</Label>
      <Input
        type="text"
        value={pageRange}
        onChange={(e) => setPageRange(e.target.value)}
        placeholder="z.B., 1-4,5,6"
      />
      <Button onClick={handleSubmit} disabled={isPending}>
        {isPending ? <i className="fas fa-spinner fa-spin"></i> : 'Teilen'}
      </Button>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && (
        <ErrorMessage style={{ color: '#2ecc71' }}>{success}</ErrorMessage>
      )}
    </Container>
  )
}
