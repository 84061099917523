import React, { useState, useContext, useEffect, useRef } from 'react'

import { Container, Sidebar } from './styles/zieb'
import DocumentViewer from '../documentViewer/DocumentViewer'
import DocumentAttributes from '../documentAttr/DocumentAttributes'
import DocumentSplit from '../documentSplit/DocumentSplit'
import DocumentCaseData from '../documentCaseData/DocumentCaseData'
import Spinner from '../../../engine-ui/Spinner'

import Context from '../../context/Context'
import useDocument from '../../hooks/useDocument'
import useMetaData from '../../hooks/useMetaData'
import useCaseData from '../../hooks/useCaseData'
import useContentPages from '../../hooks/useContentPages'
import useCallbackStatus from '../../hooks/useCallbackStatus'

import client from '../../../../../../client'

export default function ZiebContainer() {
  const { documentId } = useContext(Context)
  const { document, initializeDocument } = useDocument()
  const { metaData, initializeMetaData } = useMetaData()
  const { caseData, initializeCaseData, removeCaseData } = useCaseData()
  const { initializeContentPages, removeContentPages } = useContentPages()
  const { run: runFetchData } = useCallbackStatus()
  const timeoutCaseDataId = useRef(null)
  const [dataLoaded, setDataLoaded] = useState(false)
  const { token } = useContext(Context)

  useEffect(() => {
    const headers = {
      token: token,
    }
    runFetchData(
      client.get(`/api/admin/zieb/${documentId}`, null, headers),
    ).then(({ result }) => {
      const { document, document_types: metadata } = result

      initializeDocument(document)
      initializeMetaData(metadata)
    })
  }, [])

  useEffect(() => {
    if (document.initialized && metaData.initialized) {
      const dataPagesCheck = hasDataPages(
        document.data.type,
        metaData.data[document.data.direction].document_types,
      )

      if (dataPagesCheck) {
        getDataPages()
      } else {
        if (timeoutCaseDataId.current) clearTimeout(timeoutCaseDataId.current)
        removeCaseData()
        removeContentPages()
      }
    }
  }, [document.initialized && metaData.initialized && document.data.type])

  const getDataPages = () => {
    const headers = {
      token: token,
    }
    runFetchData(
      client.get(
        `/api/admin/zieb/documents/${documentId}/data_page`,
        null,
        headers,
      ),
    ).then(({ result }) => {
      const { data, sections, data_is_loaded } = result
      initializeCaseData(data)
      initializeContentPages(sections)
      clearTimeout(timeoutCaseDataId.current)
      if (data_is_loaded) {
        setDataLoaded(true)
      } else {
        setDataLoaded(false)
        timeoutCaseDataId.current = setTimeout(() => {
          getDataPages()
        }, 10000)
      }
    })
  }

  const hasDataPages = (documentType, data) => {
    return Object.values(data)
      .flat()
      .some(({ id, has_data_pages }) => documentType === id && has_data_pages)
  }

  if (document.initialized) {
    return (
      <Container className="zieb">
        <DocumentViewer />
        <Sidebar>
          <DocumentAttributes />
          {document.data.can_be_splitted && (
            <DocumentSplit maxPages={document.data.total_pages} />
          )}
          {caseData.initialized ? (
            dataLoaded ? (
              <DocumentCaseData />
            ) : (
              <Spinner />
            )
          ) : null}
        </Sidebar>
      </Container>
    )
  } else return <Spinner />
}
